import React, { useCallback, useEffect, useState } from "react";
import { Grid, makeStyles } from "@material-ui/core";
import { useDropzone } from "react-dropzone";
import { SingleFileUpload } from "./SingleFileUpload";
import { useField } from "formik";

const useStyles = makeStyles((theme) => ({
  dropzone: {
    border: `2px dashed ${theme.palette.primary.main}`,
    borderRadius: theme.shape.borderRadius,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background.default,
    height: theme.spacing(10),
    outline: "none",
  },
}));

let currentId = 0;

const getNewId = () => {
  return ++currentId;
};

export const MultipleFileUpload = ({ name, token }) => {
  const [_, __, helpers] = useField(name);
  const classes = useStyles();

  const [files, setFiles] = useState([]);

  const onDrop = useCallback((accFiles, rejFiles) => {
    const mappedAcc = accFiles.map((file) => ({
      file,
      errors: [],
      id: getNewId(),
    }));
    const mappedRej = rejFiles.map((r) => ({ ...r, id: getNewId() }));
    setFiles((curr) => [...curr, ...mappedAcc, ...mappedRej]);
  }, []);

  useEffect(() => {
    helpers.setValue(files);
  }, [files]);

  const onUpload = (file, url) => {
    setFiles((curr) =>
      curr.map((fw) => {
        if (fw.file === file) {
          return { ...fw, url };
        }
        return fw;
      })
    );
  };

  const onDelete = (file) => {
    setFiles((curr) => curr.filter((fw) => fw.file !== file));
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: ["image/*", "video/*", ".pdf"],
    maxSize: 300 * 1024, // 300KB
  });

  return (
    <React.Fragment>
      <Grid item>
        <div {...getRootProps({ className: classes.dropzone })}>
          <input {...getInputProps()} />

          <p>
            Перетащите сюда несколько файлов или щелкните, чтобы выбрать файлы
          </p>
        </div>
      </Grid>

      {files.map((fileWrapper) => (
        <Grid item key={fileWrapper.id}>
          <SingleFileUpload
            onDelete={onDelete}
            onUpload={onUpload}
            file={fileWrapper.file}
            token={token}
          />
        </Grid>
      ))}
    </React.Fragment>
  );
};
