import axios from "axios";
import { toast } from "react-toastify";

export const uploadFiles = (files, code) => {
  const formData = new FormData();
  const token = code;

  for (let key in files) {
    formData.append(`file_${key}`, files[key].file, files[key].file.name);
  }

  formData.append("code", token);

  axios
    .post("/action/upload", formData, {
      header: {
        "Content-Type": "multipart/form-data",
      },
    })
    .then((res) => {
      console.log("Success", res.data);
      toast.success("Загрузка завершена!");
    })
    .catch((error) => {
      console.log(error);
      toast.error("Произошла ошибка!");
    });
};
