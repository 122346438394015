import React from "react";
import { Grid, Paper, TextField, Button } from "@material-ui/core";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";

export const Login = ({ setNote, token, setToken, setOpen }) => {
  const paperStyle = {
    padding: 20,
    height: "30vh",
    width: 300,
    margin: "0 auto",
  };

  const onChange = (e) => {
    setToken(e.target.value);
  };

  const handleClick = () => {
    axios
      .post(
        "/action/checkCode",
        { code: token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        let dataNote = res.data.note;
        setNote(dataNote);
        setToken(token);
        setOpen(true);
        toast.success("Готово!");
      })
      .catch((e) => {
        setOpen(false);
        toast.error("Неверный пароль");
      });
  };

  return (
    <Grid>
      <Paper style={paperStyle}>
        <Grid align="center">
          <h2>Для загрузки файла необходимо указать код</h2>
        </Grid>
        <Grid style={{ marginBottom: "30px" }}>
          <TextField
            label="Введите код"
            type="password"
            onChange={onChange}
            value={token}
          />
        </Grid>
        <Grid item>
          <Button
            type="submit"
            color="primary"
            variant="contained"
            disabled={!token.length}
            onClick={handleClick}
            style={{ width: "35%" }}
          >
            Войти
          </Button>
        </Grid>
      </Paper>
      <ToastContainer position={toast.POSITION.TOP_RIGHT} autoClose={2000} />
    </Grid>
  );
};
