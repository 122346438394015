import { Button, Grid } from "@material-ui/core";
import React from "react";

export const FileHeader = ({ file, onDelete }) => {
  return (
    <Grid container justifyContent="space-between" alignItems="center">
      <Grid item>{file.name}</Grid>
      <Grid item>
        <Button size="small" onClick={() => onDelete(file)}>
          Удалить
        </Button>
      </Grid>
    </Grid>
  );
}
