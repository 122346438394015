import React, { useState } from "react";
import { Form, Formik } from "formik";
import { Button, Card, CardContent, Container, Grid } from "@material-ui/core";
import { toast, ToastContainer } from "react-toastify";

import { uploadFiles } from "./api/uploadFiles";
import { MultipleFileUpload } from "./components/Uploader/MultipleFileUpload";
import { Login } from "./components/Login";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [token, setToken] = useState("");
  const [note, setNote] = useState("");
  const [open, setOpen] = useState(false);

  const handleSubmit = (values) => {
    uploadFiles(values.files, token);
  };

  return (
    <Container>
      {open ? (
        <Card>
          <CardContent style={{ padding: "15px 40px" }}>
            <h1>Загрузка файлов</h1>
            <p>{note}</p>
            <Formik initialValues={{ files: [] }} onSubmit={handleSubmit}>
              <Form>
                <Grid container spacing={2} direction="column">
                  <MultipleFileUpload name="files" token={token} />

                  <Grid item>
                    <Button variant="contained" color="primary" type="submit">
                      Отправить
                    </Button>
                  </Grid>
                </Grid>
              </Form>
            </Formik>
            <ToastContainer
              position={toast.POSITION.TOP_RIGHT}
              autoClose={2000}
            />
          </CardContent>
        </Card>
      ) : (
        <Login
          token={token}
          setToken={setToken}
          setNote={setNote}
          setOpen={setOpen}
        />
      )}
    </Container>
  );
};

export default App;
