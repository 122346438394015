import React, { useState } from "react";
import { Grid, LinearProgress } from "@material-ui/core";
import { FileHeader } from "./FileHeader";

export const SingleFileUpload = ({ file, onDelete, onUpload, token }) => {
  const [progress, setProgress] = useState(0);

  return (
    <Grid item>
      <FileHeader file={file} onDelete={onDelete} />
      <LinearProgress variant="determinate" value={progress} />
    </Grid>
  );
};
